<template>
  <v-container class="mt-5">
    <alert />
    <p class="red--text font-italic caption pa-3" v-if="refereeDisclaimer">
      <v-icon color="red" small>mdi-alert</v-icon>
      {{ refereeDisclaimerCaption }}
    </p>
    <v-data-table
      :headers="headers"
      :items="referees"
      dense
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-h6">My Referees</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog persistent v-model="dialog" max-width="50%">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Add Referee
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="primary">
                <span class="text-h5">
                  {{ edit ? "Edit" : "Add" }} Referee
                </span>
              </v-card-title>

              <v-card-text class="mt-10">
                <v-container>
                  <alert />
                  <v-row class="d-flex justify-space-between" dense>
                    <v-col cols="12" md="10">
                      <v-text-field
                        dense
                        outlined
                        v-model="searchForm.search"
                        :rules="rules.Name"
                        ref="Search"
                        @input="
                          (val) =>
                            (searchForm.search =
                              searchForm.search.toUpperCase())
                        "
                      >
                        <template v-slot:label>
                          <div>
                            Search by Member Number
                            <span class="red--text">*</span>
                          </div>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="2">
                      <v-btn color="primary" @click="Search">
                        <v-icon>mdi-magnify</v-icon>
                        search
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        dense
                        outlined
                        v-model="formData.Name"
                        :rules="rules.Name"
                        ref="Name"
                        @input="
                          (val) => (formData.Name = formData.Name.toUpperCase())
                        "
                      >
                        <template v-slot:label>
                          <div>
                            Name
                            <span class="red--text">*</span>
                          </div>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        dense
                        outlined
                        v-model="formData.PhoneNo"
                        :rules="rules.PhoneNo"
                        v-on:keypress="NumbersOnly"
                        ref="PhoneNo"
                      >
                        <template v-slot:label>
                          <div>
                            Phone No
                            <span class="red--text">*</span>
                          </div>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        dense
                        outlined
                        v-model="formData.Email"
                        :rules="rules.Email"
                        @input="
                          (val) =>
                            (formData.Email = formData.Email.toUpperCase())
                        "
                        ref="Email"
                      >
                        <template v-slot:label>
                          <div>
                            Email
                            <span class="red--text">*</span>
                          </div>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        dense
                        outlined
                        v-model="formData.Address"
                        ref="Address"
                        @input="
                          (val) =>
                            (formData.Address = formData.Address.toUpperCase())
                        "
                      >
                        <template v-slot:label>
                          <div>Address</div>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        dense
                        outlined
                        v-model="formData.PlaceOfWork"
                        ref="PlaceOfWork"
                        @input="
                          (val) =>
                            (formData.PlaceOfWork =
                              formData.PlaceOfWork.toUpperCase())
                        "
                      >
                        <template v-slot:label>
                          <div>Place of Work</div>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        dense
                        outlined
                        v-model="formData.Designation"
                        ref="Designation"
                        @input="
                          (val) =>
                            (formData.Designation =
                              formData.Designation.toUpperCase())
                        "
                      >
                        <template v-slot:label>
                          <div>Designation</div>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        dense
                        outlined
                        v-model="formData.MemberNo"
                        :rules="rules.memberNo"
                        ref="MemberNo"
                        @input="
                          (val) =>
                            (formData.MemberNo =
                              formData.MemberNo.toUpperCase())
                        "
                      >
                        <template v-slot:label>
                          <div>
                            Member No
                            <span class="red--text">*</span>
                          </div>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                      v-if="refereeTypes.length > 0"
                    >
                      <v-autocomplete
                        :items="refereeTypes"
                        :item-text="(item) => item.code"
                        :item-value="(item) => item.description"
                        v-model="formData.refereeType"
                        :rules="rules.refereeType"
                        ref="refereeType"
                        dense
                        outlined
                        label="Referee Type"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="clearForm"> Cancel </v-btn>
                <v-btn color="primary" @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.response="{ item }">
        {{ item.response === " " ? "- - -" : item.response + "ed" }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" color="blue" @click="update(item)">
          mdi-pencil
        </v-icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              color="green"
              class="mr-2"
              @click="resendEmail(item)"
            >
              mdi-email-sync
            </v-icon>
          </template>
          <span>Resend Email</span>
        </v-tooltip>
        <v-icon small color="red" @click="remove(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { AuthService } from "../../../auth";
import { helper } from "../../../../utils";
import Alert from "../../../../components/Alert";

export default {
  name: "Referee",
  components: { Alert },
  data: () => ({
    dialog: false,
    edit: false,
    formData: {
      Name: "",
      Address: "",
      Email: "",
      PhoneNo: "",
      PlaceOfWork: "",
      Designation: "",
      MemberNo: "",
      refereeType: "",
    },
    searchForm: {
      search: "",
    },
  }),

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Admin/getSettings");
    });
  },

  mounted() {
    this.$store.dispatch("Profile/getReferees");
    this.$store.dispatch("Setup/getRefereeTypes");
  },

  computed: {
    rules() {
      return {
        Name: [(v) => !!v || "Name is required"],
        // Address: [(v) => !!v || "Sector Year is required"],
        Email: [
          (v) => !!v || "Email is required",
          // (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        PhoneNo: [(v) => !!v || "Phone No is required"],
        // PlaceOfWork: [(v) => !!v || "Place Of Work is required"],
        // Designation: [(v) => !!v || "Designation is required"],
        memberNo: [(v) => !!v || "Member No is required"],
        refereeType: [(v) => !!v || "Referee Type is required"],
      };
    },

    referees() {
      return this.$store.getters["Profile/referees"];
    },

    headers() {
      const headers = [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "Name",
        },
        { text: "Address", value: "Address" },
        { text: "Email", value: "Email" },
        { text: "Phone No", value: "PhoneNo" },
        { text: "Place of Work", value: "PlaceOfWork" },
        { text: "Designation", value: "Designation" },
        { text: "Member No", value: "memberNo" },
        { text: "Actions", value: "actions", sortable: false },
      ];
      if (this.referees.find((r) => r.response.length > 0)) {
        headers.splice([headers.length - 1], 0, {
          text: "Response",
          value: "response",
        });
        headers.join();
      }
      if (this.refereeTypes.length > 0) {
        headers.splice([headers.length - 1], 0, {
          text: "Referee Type",
          value: "refereeType",
        });
        headers.join();
      }
      return headers;
    },

    user() {
      return AuthService.user;
    },

    searchedReferee() {
      return this.$store.getters["Profile/referee"];
    },

    refereeDisclaimer() {
      return this.minorSettings ? this.minorSettings.referee_disclaimer : false;
    },

    refereeDisclaimerCaption() {
      return this.minorSettings
        ? this.minorSettings.referee_disclaimer_caption
        : "";
    },

    refereeTypes() {
      return this.$store.getters["Setup/refereeTypes"];
    },
  },

  methods: {
    clearForm: function () {
      this.formData = {
        Name: "",
        Address: "",
        Email: "",
        PhoneNo: "",
        PlaceOfWork: "",
        Designation: "",
        MemberNo: "",
      };

      this.searchForm = {
        search: null,
      };

      this.edit = false;
      this.dialog = false;
    },

    update: function (payload) {
      this.formData = { ...payload };
      this.formData.MemberNo = payload.memberNo;
      this.edit = true;
      this.dialog = true;
    },

    remove(item) {
      this.$confirm.show({
        title: "Delete this Referee?",
        text: `Are you sure you want to delete?`,
        onConfirm: () => {
          this.$store.dispatch("Profile/removeReferee", { ...item });
        },
      });
    },

    save: function () {
      let isValid = true;
      for (const key in this.formData) {
        if (this.$refs[key])
          isValid = isValid ? this.$refs[key].validate(true) : false;
      }

      if (isValid) {
        let data = { ...this.formData };
        data.ProfileID = AuthService.user.profile.ProfileID;

        if (this.edit) {
          data.lineNo = parseInt(data.lineNo);
          data.EntryNo = this.formData.EntryNo
            ? parseInt(this.formData.EntryNo)
            : parseInt(this.referees.length + 1);
        }

        this.$store.dispatch(
          `Profile/${this.edit ? "updateReferee" : "addReferee"}`,
          helper.prepareFormData(data)
        );

        this.clearForm();
      }
    },

    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    resendEmail(item) {
      this.$store.dispatch("Profile/sendEmail", item);
    },

    Search() {
      this.$store.dispatch("Profile/searchReferee", this.searchForm);
    },
  },

  watch: {
    searchedReferee() {
      const workExperience = this.searchedReferee.workExperience;
      this.formData.Name =
        this.searchedReferee.FirstName +
        " " +
        this.searchedReferee.SecondName +
        " " +
        this.searchedReferee.LastName;
      this.formData.Email = this.searchedReferee.Email;
      this.formData.MemberNo = this.searchedReferee.MemberNo;
      if (workExperience) {
        this.formData.PhoneNo = workExperience.PhoneNo;
        this.formData.Address = workExperience.Address;
        this.formData.PlaceOfWork = workExperience.PlaceOfWork;
        this.formData.Designation = workExperience.Designation;
      }
    },
  },
};
</script>

<style scoped></style>
